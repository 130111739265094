@import url('https://fonts.googleapis.com/css2?family=Karla:wght@400;700&family=Poppins:wght@400;700&display=swap');

:root {
    --black: #150605;
    --red: #c02a22;
    --blue: #6eb3f4;
    --darker_blue: #2c89e07a;
    --violet: #a731dd;
}


/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}


/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
   scroll-behavior: auto;
  }
  
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

html, body {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
}

.App {
  text-align: center;
 
}

header {
    display: grid;
    grid-template-columns: repeat(3, 1fr);;
    gap: 16px;
    align-items: center;
    padding-block: 1em;
    padding-inline: 2em;
    background-color: var(--blue);
    color: var(--black);
    margin: 0em auto 2em;
    -webkit-box-shadow: 0px 2px 10px -5px rgba(21,6,5,1);
    -moz-box-shadow: 0px 2px 10px -5px rgba(21,6,5,1);
    box-shadow: 0px 2px 10px -5px rgba(21,6,5,1);
}
header h1 {
  margin-right: auto;
}

header a {
  text-decoration: none;
  padding: 6px;
  border-radius: 4px;
}

a {
    text-decoration: none;
}

a:visited { color: var(--black); }
a:hover { color: var(--black); }
a:active { color: var(--black); }

header > * {
  
}

nav {
  display: flex;
  justify-content: flex-end;
}
.nav__icons {
  font-size: 2em;
  padding : 0 .5em;
  cursor: pointer;
  transition: ease-in-out 0.3s;
}

.nav__icons:hover {
  transform: scale(1.4);
}

.stacked {
  display: grid;
  text-align: left;
}

.stacked > * {
  grid-column: 1/2;
  grid-row: 1/2;
}

.home__container {
max-width: 80rem;
margin-inline: auto;
padding-inline: 2rem;
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  gap: 0.5rem;
}
.card {
  aspect-ratio: 1/ 1.5;
}

.card__content {
  align-self: end;
  margin: 0.5rem 0.5rem 2rem;
  padding: 0.5rem;
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.1);
}

.card__img {
  width: 100%;
  aspect-ratio: 1/1.25;
  object-fit: cover;
}
.card__content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: white;
  align-self: end;
  margin: 0.5rem;
  padding: 0.6em;
}
.card__title {
  font-size: 1.3rem;
  line-height: 1.2;
}
.card__price {
  letter-spacing: 0.1em;
}

.card__description {
  font-size: 0.8rem;
  padding-right: 0.2em;
}

.card__actions {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}


.card__actions_icon {
  font-size: 1.5em;
  cursor: pointer;
  transition: ease-in-out 0.2s;
}

.icon {
  font-size: 2rem;
  cursor: pointer;
  margin-right: 1em;
}

.card__actions_icon:hover {
  transform: scale(1.4);
}


.cart__container {
  max-width: 50vw;
  margin-inline: auto;
  padding-inline: 2rem;
}

.cart__elements {
  padding: 1em;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-items: center;
}

.page__description{
  padding: 1em;
  text-align: left;
 }
 .page__container {
  padding: 1em;
 }

.cart__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .25em;
}

.cart__item__box {
 display: flex;
 flex-direction: row;
 align-items: center;
}

.cart__item__img {
  aspect-ratio: 1/1;
  object-fit: cover;
  width: 5em;
}

.cart__item_delete{
  font-size: 2em;
  margin-right: 1em;  
  cursor: pointer;
}

.cart__item__details {
  display: flex;
  flex-direction: row;
  align-items:center;
  justify-content: space-around;
}
.cart__item__title {
  align-items: center;
  margin-left: 1em;
  text-align: left;
}
.cart__total {
  margin-block: 0.5em;
  display: flex;
  justify-content: flex-end;
}

.cart__total h2:first-child {
 margin-right: 1em;
}

.cart__counter {
  display: flex;
  flex-direction: column;
}

.place_order__btn {
  max-width: fit-content;
}
.btn {
  letter-spacing: 0.15em;
  margin-inline: auto;
  margin-block: 1em;
  padding: 1em;
  border-style: none;
  background-color: var(--black);
  color: white;
}
.btn:hover {
  background-color: var(--blue);
  color: var(--dark);
  cursor: pointer;
}

.full_heart {
  color:#dd524b
}
.account__page {
  margin-inline: auto;
  max-width: 80vh;
  display: flex;
  justify-content: center;
}

.login__container {
  display: grid;
  gap: .5em;
  max-width: fit-content;
  margin-inline: auto;
}
.login__input__box {
  display: flex;
  justify-content:space-between;
  align-items: center;
}
.login__input__box > label {
  display: block;
  margin-right: 2em;
}
.login__btn {
  margin-block: 1.5em;
}

.account__details_container {
  width: 80vw;
  display: flex;
  flex-direction: column;
}
.greetings {
  margin-block: .5em;
}
/* 
@media (min-width: 60em) {

.featured card is not present
  .card.featured {
    grid-row: span 2;
    grid-column: span 2;
  }

  .card.featured .card__img {
    aspect-ratio: 1/1.4;
  }

  .card.featured .card__title {
    font-size: 1.75rem;
  }
} */


@media(max-width: 600px ){
  .cart__container {
    max-width: 100%;
  }
  .icon {
    font-size: 1.5rem;
    cursor: pointer;
    margin-right: 0.5em;
  }
  .nav__icons {
    font-size: 2em;
    padding : 0.1em;
    }
  .header {
    padding:0; 
  }
  header h1 {
    font-size: 2em;
  }

  .cart__item__title {
    margin-left: .2em;
    padding-right: .4em;
  }
}